<template>
  <div>
    <b-modal
      :id="ids"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      dialog-class="wd-customSubs m-auto"
    >
      <div class="d-block text-center r-dx">
        <h3 class="text-white" style="font-size: 4.2vw; margin-top: 24px;">Menangkan UANG JUTAAN, IPHONE 14 PROMAX, YAMAHA FAZIO dan lainnya!!<br> Klik LANJUT</h3>
        <img class="btn-x" src="@/assets/popup/icon_close.png" @click="hidePopupSubs(ids)"/>
        <img src="@/assets/popup/Prize.png" style="width: 100%"/>
        <b-button
          class="mt-3 button-lanjut"
          @click="tambahToken()"
          >LANJUT</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  props: {
      // dataPopup: Object,
      ids: String,
    },
  methods: {
      showPopupSubs(id) {
          this.$bvModal.show(id);
      },
      hidePopupSubs(id) {
          this.$bvModal.hide(id);
      },
      tambahToken() {
        //   this.$store.state.coin = 1
          this.hidePopupSubs(this.ids)
          // this.$emit('addCoin')
        // this.renderComponent = true;
          // console.log('clicked', this.$store.state.coin);
          if (this.ids === 'popupStatusSubs') {
            location.replace('http://117.54.3.23:1481/smart?aid=88&adn=99878&keyword=cuan3')
          }
      }
    // showPopupHadiah(point) {
    //   const index = this.reward.findIndex(
    //     item => item.point === point
    //   );
    //   const reward = this.reward[index];
    //   this.dataPopup.image = reward.image;
    //   this.dataPopup.title = reward.title;
    //   this.dataPopup.description = reward.description;
    //   this.dataPopup.txtButton = "Ambil";
    //   this.$bvModal.show("popupModal");
    // },
    // showPopupValidateToken() {
    //   const token = this.token;
    //   this.dataPopup.image = token.image;
    //   this.dataPopup.title = token.title;
    //   this.dataPopup.description = token.description;
    //   this.dataPopup.txtButton = "Tambah Token";
    //   console.log('muncul', this.point);
    //   this.$bvModal.show("popupModal");
    //   // this.$refs['popupModal'].show()
    // },
    // setPopup() {
    //     if (this.totalPoint !== null) {
    //       if (this.totalPoint === '0') {
    //           this.showPopupValidateToken()
    //       } else {
    //           this.showPopupHadiah(this.totalPoint)
    //       }
    //   } else {
    //       this.$bvModal.hide("popupModal")
    //   }
      
    // },
  }
};
</script>
<style scoped>
::v-deep .modal-content {
  background: transparent !important;
  border: none !important;
    background-image: url("../assets/popup/BG.png") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    border-radius: 42px;
}
::v-deep .wd-customSubs {
    width: 70% !important;
}
::v-deep .title-popup {
    font-size: 1.1rem;
}
::v-deep .txt-desc {
    font-size: .8rem;
}
::v-deep .button-lanjut {
  margin-bottom: 24px;
  background: white;
  border: none;
  border-radius: 30px;
  color: #F32424;
  width: 50%;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}
::v-deep .btn-x {
  width: 16%;
  position: absolute;
  top: -6%;
  right: -12%;
}
</style>
