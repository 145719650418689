import { render, staticRenderFns } from "./PopupStatusSubs.vue?vue&type=template&id=da53cdfc&scoped=true&"
import script from "./PopupStatusSubs.vue?vue&type=script&lang=js&"
export * from "./PopupStatusSubs.vue?vue&type=script&lang=js&"
import style0 from "./PopupStatusSubs.vue?vue&type=style&index=0&id=da53cdfc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da53cdfc",
  null
  
)

export default component.exports